<template>
  <div>

    <!-- Filter -->
    <div class="row align-items-center justify-content-between mt-n3">

      <div :class="hasPatient ? 'col-md-9' : 'col-md-6'">
        <button
          class="btn mx-1 btn-timeseries"
          @click="btnActiveOnClick('days')"
          v-bind:class="{ 'btn-info': btnActive.days, 'btn-outline-info': btnActive.days == false }">Hari Ini</button>
        <button
          class="btn mx-1 btn-timeseries"
          @click="btnActiveOnClick('month')"
          v-bind:class="{ 'btn-info': btnActive.month, 'btn-outline-info': btnActive.month == false }">Bulan Ini</button>
        <button
          class="btn mx-1 btn-timeseries"
          @click="btnActiveOnClick('lastMonth')"
          v-bind:class="{ 'btn-info': btnActive.lastMonth, 'btn-outline-info': btnActive.lastMonth == false }">Bulan Kemarin</button>
        <button
          class="btn mx-1 btn-timeseries"
          @click="btnActiveOnClick('year')"
          v-bind:class="{ 'btn-info': btnActive.year, 'btn-outline-info': btnActive.year == false }">Tahun Ini</button>
        <button
          class="btn mx-1 btn-timeseries"
          v-bind:class="{ 'btn-info': btnActive.period, 'btn-outline-info': btnActive.period == false }"
          @click="btnPeriodeOnClick">Periode</button>
      </div>

      <div
        class="col-md-3 col-6 mt-md-0 mt-n4"
        v-if="currentUser.user_type_id != 1">
        <treeselect
          v-model="filter.doctor_id"
          :multiple="false"
          :options="doctors"
          @select="filterByDoctor"
          placeholder="Cari Berdasar Dokter" />
      </div>
      <div
        class="col-md-3 col-6 mt-md-0 mt-n4"
        v-if="!hasPatient">
        <b-input-group>
          <template #prepend>
            <b-input-group-text squared>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input
            type="text"
            v-model="filter.patient_name"
            placeholder="Cari Nama Pasien"
            @keyup="filterByPatient"></b-form-input>
        </b-input-group>
      </div>
    </div>

    <div class="d-flex justify-content-between align-items-center mt-5">
      <div
        class="col-md-6"
        v-if="onShowPeriod">
        <span class="d-none d-sm-block">
          <b-input-group>

            <b-form-input
              v-model="filter.start_date"
              type="text"
              placeholder="Tanggal Awal"
              autocomplete="off"
              readonly></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                v-model="filter.start_date"
                button-only
                right
                reset-button
                locale="id"
                aria-controls="example-input"></b-form-datepicker>
            </b-input-group-append>
            <b-button
              squared
              variant="light">s/d</b-button>
            <b-form-input
              v-model="filter.end_date"
              type="text"
              placeholder="Tanggal Akhir"
              autocomplete="off"
              readonly></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                v-model="filter.end_date"
                button-only
                right
                reset-button
                locale="id"
                aria-controls="example-input"></b-form-datepicker>
            </b-input-group-append>
            <template #append>
              <b-button
                squared
                @click="filterByPeriode"
                variant="success">Cari</b-button>
              <b-button
                squared
                @click="resetFilterPeriode"
                variant="danger">Reset</b-button>
            </template>
          </b-input-group>
        </span>
        <span class="d-block d-sm-none">
          <div class="row">
            <div class="col-12 pb-1">
              <b-input-group>
                <b-form-input
                  v-model="filter.start_date"
                  type="text"
                  placeholder="Tanggal Awal"
                  autocomplete="off"
                  readonly></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="filter.start_date"
                    button-only
                    right
                    reset-button
                    locale="id"
                    aria-controls="example-input"></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </div>
            <div class="col-12 pt-0">
              <b-input-group>
                <b-button
                  squared
                  variant="light">s/d</b-button>
                <b-form-input
                  v-model="filter.end_date"
                  type="text"
                  placeholder="Tanggal Akhir"
                  autocomplete="off"
                  readonly></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="filter.end_date"
                    button-only
                    right
                    reset-button
                    locale="id"
                    aria-controls="example-input"></b-form-datepicker>
                </b-input-group-append>

                <template #append>
                  <b-button
                    squared
                    @click="filterByPeriode"
                    variant="success">Cari</b-button>
                  <b-button
                    squared
                    @click="resetFilterPeriode"
                    variant="danger">Reset</b-button>
                </template>
              </b-input-group>
            </div>
          </div>
        </span>
      </div>

      <div
        class="col-md-6"
        v-if="!onShowPeriod"></div>

      <div class="col-md-4 d-flex justify-content-end">
        <button
          class="btn btn-primary mr-1"
          @click="btnExportOnClick">
          Expor <i class="fas fa-print px-0 ml-1"></i>
        </button>
      </div>

    </div>

    <div class="row">
      <div class="col-md-2">
        <span
          style="width: 150px; color: #245590 !important"
          class="font-weight-bold">{{ recordData }} Baris Data</span>
      </div>
    </div>

    <!-- Table -->
    <b-table
      striped
      hover
      responsive
      class="mt-3"
      :items="items"
      :fields="fields">
      <template #cell(final_amount)="data">
        {{ parseInt(data.item.final_amount).toLocaleString("id-ID") }}
      </template>
      <template #cell(paid_amount_x_unpaid_amount)="data">
        <span class="d-block">Terbayar <span class="font-weight-bold">{{ parseInt(data.item.paid_amount).toLocaleString("id-ID") }}</span></span>
        <span class="d-block">Kekurangan <span class="font-weight-bold">{{ parseInt(data.item.unpaid_amount).toLocaleString("id-ID") }}</span></span>
      </template>
      <template #cell(payment_status_id)="data">
        <div class="d-flex justify-content-center">
          <button :class="`btn btn-sm font-weight-bold ${btnStatusColor(data.item.payment_status_id)}`">{{ paymentStatus(data.item.payment_status_id) }}</button>

          <b-button
            size="sm"
            class="mx-1 btn-info"
            v-if="data.item.payment_status_id == 4"
            @click="showInfo(data.item)"><i class="fas fa-info px-0 fa-sm"></i></b-button>

        </div>
      </template>
      <template #cell(actions)="data">
        <div class="d-flex">
          <b-button
            size="sm"
            class="mr-1 btn-info"
            v-b-tooltip.hover
            title="Detail"
            @click="$router.push({ path: '/payment/detail/' + data.item.id })"><i class="fas fa-eye px-0"></i></b-button>
          <b-button
            v-if="data.item.payment_status_id != 3 && data.item.payment_status_id != 4 && manipulateBtn == true"
            size="sm"
            class="mr-1 btn-success"
            v-b-tooltip.hover
            title="Edit"
            @click="$router.push({ path: '/payment/edit/' + data.item.id })"><i class="fas fa-edit px-0"></i></b-button>
          <b-button
            v-if="data.item.payment_status_id == 1 && showDeleteBtn == true"
            size="sm"
            class="btn-danger"
            v-b-tooltip.hover
            title="Void"
            @click="btnVoidInvoiceOnClick(data.item)"><i class="fas fa-trash px-0"></i></b-button>
          <b-button
            v-if="data.item.payment_status_id == 3 && showVoidBtn == true"
            size="sm"
            class="btn-danger"
            v-b-tooltip.hover
            title="Void"
            @click="btnVoidPaidOffOnClick(data.item)"><i class="fas fa-trash px-0"></i></b-button>
        </div>
      </template>
    </b-table>
    <b-pagination
      v-if="items.length != 0"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-click="pageOnClick"
      class="mt-4"></b-pagination>

    <b-modal
      id="modal-info"
      title="Catatan Void"
      hide-footer>
      <span><b>Catatan : </b> {{ textNotes }}</span>
    </b-modal>

    <!-- Modal Void Invoice -->
    <b-modal
      id="modal-void-invoice"
      title="Otorisasi"
      ok-title="Konfirmasi"
      cancel-title="Batal"
      @ok="modalVoidInvoiceOnSubmit"
      @hidden="modalVoidInvoiceOnHide">

      <h5>Keterangan :</h5>

      <!-- Input notes-->
      <b-form-group
        id="input-group-note"
        label="Catatan/Sebab Void:"
        label-for="input-note"
        description="">

        <b-form-textarea
          id="input-note"
          v-model="form.note"
          placeholder="catatan"
          rows="3"
          max-rows="6"></b-form-textarea>

        <small class="text-danger">{{ error.note }}</small>
      </b-form-group>

      <h5>Akun Otorisasi :</h5>

      <!-- Input Email -->
      <b-form-group
        id="input-group-email"
        label="Email:"
        label-for="input-email"
        description="">
        <b-form-input
          id="input-email"
          v-model="form.email"
          type="email"
          placeholder="Email"></b-form-input>
        <small class="text-danger">{{ error.email }}</small>
      </b-form-group>
      <!-- Password Input -->
      <div class="form-group">
        <label for="password">Password:</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <div
              class="input-group-text"
              @click="passwordIsVisible == 'password' ? passwordIsVisible = 'text' : passwordIsVisible = 'password'">
              <div v-if="passwordIsVisible == 'password'">
                <b-icon-eye-slash></b-icon-eye-slash>
              </div>
              <div v-if="passwordIsVisible == 'text'">
                <b-icon-eye></b-icon-eye>
              </div>
            </div>
          </div>
          <input
            :type="passwordIsVisible"
            class="form-control"
            placeholder="Password"
            v-model="form.password"
            id="password">
        </div>
        <small class="text-danger">{{ error.password }}</small>
      </div>
    </b-modal>

    <!-- modal export -->
    <b-modal
      id="modal-excel"
      size="xl"
      title="Expor Daftar Pembayaran Klinik"
      style="max-width: 30px; overflow: scroll;"
      hide-footer>
      <div class="d-flex justify-content-start align-items-center mt-5">
        <button
          class="btn btn-warning mr-1"
          v-b-tooltip.hover
          title="Download Excel"
          @click="btnExcelOnClick">
          <i class="fas fa-file-excel px-0"></i>
        </button>

      </div>
      <b-table
        striped
        hover
        responsive
        class="mt-3"
        :items="itemPopUp"
        :fields="fieldPopUp">
        <template #table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: hasDoctor || hasPatient ? field.key === 'actions' ? '15%' : '' : field.key === 'actions' ? '25%' : '' }" />
        </template>
        <template #cell(final_amount)="data">
          {{ parseInt(data.item.final_amount).toLocaleString("id-ID") }}
        </template>
        <template #cell(paid_amount_x_unpaid_amount)="data">
          <span class="d-block">Terbayar <span class="font-weight-bold">{{ parseInt(data.item.paid_amount).toLocaleString("id-ID") }}</span></span>
          <span class="d-block">Kekurangan <span class="font-weight-bold">{{ parseInt(data.item.unpaid_amount).toLocaleString("id-ID") }}</span></span>
        </template>
        <template #cell(payment_status_id)="data">
          <div class="d-flex justify-content-center">
            <button :class="`btn btn-sm font-weight-bold ${btnStatusColor(data.item.payment_status_id)}`">{{ paymentStatus(data.item.payment_status_id) }}</button>
          </div>
        </template>
      </b-table>
    </b-modal>

  </div>
</template>

<script>

import XLSX from "xlsx";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { debouncer } from "@/core/modules/Helper.js"
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import { getUser } from "@/core/services/jwt.service.js";
import moment from 'moment';
import Swal from 'sweetalert2';

export default {

  props: {
    hasPatient: Boolean,
    fields: Array,
    items: Array,
    fieldPopUp: Array,
    perPage: Number,
    currentPage: Number,
    totalRows: Number,
    recordData: Number,
  },

  components: {
    Treeselect,
  },

  data() {
    return {
      // data
      itemExcel: [],
      itemPopUp: [],
      // Filter
      filter: {
        doctor_id: "",
        patient_name: "",
        start_date: "",
        end_date: "",
      },
      // filter timeseries
      onShowPeriod: false,
      btnActive: {
        days: true,
        month: false,
        lastMonth: false,
        year: false,
        period: false,
      },
      lastPeriodType: "days",
      // form
      form: {
        _method: 'put',
        id: '',
        email: '',
        password: '',
        note: ''
      },
      error: {
        email: '',
        password: '',
        note: ''
      },
      // Options
      doctors: [],
      manipulateBtn: false,
      showDeleteBtn: false,
      showVoidBtn: false,
      // current user
      currentUser: getUser(),
      // others
      setting: {},
      passwordIsVisible: 'password',
      textNotes: "",
    }
  },


  methods: {

    showInfo(data) {
      this.textNotes = data.notes
      this.$bvModal.show('modal-info')
    },

    async btnExportOnClick() {
      SwalLoading.fire()

      //  export
      let filterParamNoPaging = `?patient_name=${this.filter.patient_name}&doctor_id=${this.filter.doctor_id}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`
      let responseExcel = await module.get("payment-excel", `${filterParamNoPaging}`);
      let responseNoPagination = await module.get("payment-no-paginations", `${filterParamNoPaging}`);
      this.itemExcel = responseExcel
      this.itemPopUp = responseNoPagination

      if (responseExcel) {
        Swal.close();
        this.$bvModal.show('modal-excel')
      }
    },

    async getSetting() {
      this.setting = await module.get(`settings/${1}`)
    },

    btnExcelOnClick() {
      const data = XLSX.utils.json_to_sheet(this.itemExcel);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, `Daftar Pembayaran ${this.setting.clinic_name}.xlsx`);
    },

    async getDoctorsOption() {
      let response = await module.setDoctorTreeSelect("doctors");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.doctors = response.data;
        this.doctors.unshift({
          label: "Filter Berdasar Dokter",
          id: "",
          isDisabled: true,
        });
      }
    },

    btnStatusColor(id) {
      if (id == 1) {
        return 'bg-light-info text-info'
      } else if (id == 2) {
        return "bg-light-warning text-warning";
      } else if (id == 3) {
        return 'bg-light-success text-success'
      } else if (id == 4) {
        return 'bg-light-danger text-danger'
      }
    },

    paymentStatus(id) {
      if (id == 1) {
        return "Belum Bayar";
      } else if (id == 2) {
        return "Sebagian";
      } else if (id == 3) {
        return 'Lunas'
      } else if (id == 4) {
        return 'Void'
      }
    },

    pageOnClick(evt, page) {
      evt.preventDefault();
      this.$emit("pageOnClick", page);
    },

    resetFilterPeriode() {
      this.filter = validation.clearForm(this.filter);
      if (this.currentUser.user_type_id == 1) {
        this.filter.doctor_id = this.currentUser.user_id;
      }
      this.btnActiveOnClick(this.lastPeriodType)
      this.$emit("filter", this.filter);
    },

    filterByPeriode() {
      this.$emit("filter", this.filter);
    },

    async filterByDoctor() {
      await this.filter.doctor_id;
      this.filter.start_date = ""
      this.filter.end_date = ""
      this.$emit("filter", this.filter);
      // set btn Active
      this.btnActive.days = true
      this.btnActive.month = false
      this.btnActive.lastMonth = false
      this.btnActive.year = false
      this.btnActive.period = false
    },

    filterByPatient() {
      debouncer(() => {
        this.filter.start_date = ""
        this.filter.end_date = ""
        if (this.filter.patient_name == '') {
          this.btnActiveOnClick(this.lastPeriodType)
        }
        this.$emit("filterByPatient", this.filter);
      }, 500)
    },

    async modalVoidInvoiceOnSubmit(evt) {
      evt.preventDefault()
      // Make Request
      let response = await module.submit(this.form, `payments/void/${this.form.id}`)
      // Check Response
      if (response.state == 'error') {
        // Validation Error
        this.error = validation.setValidationError(this.error, response.error.errors)
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, 'success')
        this.$bvModal.hide('modal-void-invoice')
        this.$emit('pageOnClick', this.currentPage)
      }
    },

    modalVoidInvoiceOnHide() {
      this.form = validation.clearForm(this.form)
      this.form['_method'] = 'put'
      this.error = validation.clearForm(this.error)
    },

    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      let a
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "6002") {
          this.manipulateBtn = true;
        }
        if (access_right[a] == "6020") {
          this.showDeleteBtn = true
        }
        if (access_right[a] == "6019") {
          this.showVoidBtn = true
        }
      }
    },

    btnPeriodeOnClick() {
      if (this.onShowPeriod == false) {
        this.onShowPeriod = true
        this.btnActiveOnClick('period')
      } else {
        this.onShowPeriod = false
        this.btnActiveOnClick('period')
      }
    },

    btnActiveOnClick(type) {
      if (type == 'days') {
        // set time
        this.filter.start_date = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.filter.end_date = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')

        // get data
        this.$emit("filter", this.filter);

        // set btn Active
        this.btnActive.days = true
        this.btnActive.month = false
        this.btnActive.lastMonth = false
        this.btnActive.year = false
        this.btnActive.period = false

        this.lastPeriodType = "days"

        // hide period
        this.onShowPeriod = false
      } else if (type == 'month') {
        // set time
        this.filter.start_date = moment().startOf('month').startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.filter.end_date = moment().endOf('month').endOf('day').format('YYYY-MM-DD HH:mm:ss')

        // get data
        this.$emit("filter", this.filter);

        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = true
        this.btnActive.lastMonth = false
        this.btnActive.year = false
        this.btnActive.period = false

        this.lastPeriodType = "month"

        // hide period
        this.onShowPeriod = false
      } else if (type == 'lastMonth') {
        // set time
        this.filter.start_date = moment().subtract(1, 'months').startOf('month').startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.filter.end_date = moment().subtract(1, 'months').endOf('month').endOf('day').format('YYYY-MM-DD HH:mm:ss')

        // get data
        this.$emit("filter", this.filter);

        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = false
        this.btnActive.lastMonth = true
        this.btnActive.year = false
        this.btnActive.period = false

        this.lastPeriodType = "lastMonth"

        // hide period
        this.onShowPeriod = false
      } else if (type == 'year') {
        // set time
        this.filter.start_date = moment().startOf('year').startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.filter.end_date = moment().endOf('year').endOf('day').format('YYYY-MM-DD HH:mm:ss')

        // get data
        this.$emit("filter", this.filter);

        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = false
        this.btnActive.lastMonth = false
        this.btnActive.year = true
        this.btnActive.period = false

        this.lastPeriodType = "year"

        // hide period
        this.onShowPeriod = false
      } else if (type == 'period') {
        // set time
        this.filter.start_date = ""
        this.filter.end_date = ""

        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = false
        this.btnActive.lastMonth = false
        this.btnActive.year = false
        this.btnActive.period = true
      }
    },

    // btn void invoice @click
    btnVoidInvoiceOnClick(data) {
      this.form.id = data.id
      if (data.has_usage != 0) {
        Swal.fire({
          title: 'Tidak bisa void invoice ini',
          text: 'karena layanan dalam invoice ini sudah dilakukan',
          icon: 'warning'
        })
        return
      }
      this.$bvModal.show('modal-void-invoice')
    },

    btnVoidPaidOffOnClick(data) {
      this.form.id = data.id
      // if (data.has_usage != 0) {
      //   Swal.fire({
      //     title: 'Tidak bisa void invoice ini',
      //     text: 'karena layanan dalam invoice ini sudah dilakukan',
      //     icon: 'warning'
      //   })
      //   return
      // }
      this.$bvModal.show('modal-void-invoice')
    }

  },

  watch: {
    "filter.doctor_id": function (newVal, oldVal) {
      if (typeof newVal === "undefined") {
        this.filter.doctor_id = "";
        this.filterByDoctor();
      }
    },
  },

  mounted() {
    if (this.currentUser.user_type_id != 1) {
      // Get Options
      this.getDoctorsOption();
    }
    this.getSetting()
    this.setActiveMenu();
  },

};
</script>